/* Add needed sized separeted by space: $margin-px: 0 10 20 30; */
$margin-px: 0 5 10 15 20 25 30;
$margin-x-px: 0 5 10 15 20 25 30;
$margin-y-px: 0 5 10 15 20 25 30;
$margin-top-px: 0 3 5 10 12 14 15 16 20 25 30 40 42;
$margin-bottom-px: 0 3 5 10 12 14 15 20 25 30 60 100 200;
$margin-left-px: 0 5 8 10 12 14 15 20 25 30 45 70;
$margin-right-px: 0 5 8 10 12 14 15 20 25 30 45 70;

/* Generators */
@mixin margin {
  @each $i in $margin-px {
    .m-#{$i} {
      margin: #{$i}px !important;
    }
  }
  @each $i in $margin-x-px {
    .mx-#{$i} {
      margin-left: #{$i}px !important;
      margin-right: #{$i}px !important;
    }
  }
  @each $i in $margin-y-px {
    .my-#{$i} {
      margin-top: #{$i}px !important;
      margin-bottom: #{$i}px !important;
    }
  }
  @each $i in $margin-top-px {
    .mt-#{$i} {
      margin-top: #{$i}px !important;
    }
  }
  @each $i in $margin-bottom-px {
    .mb-#{$i} {
      margin-bottom: #{$i}px !important;
    }
  }
  @each $i in $margin-left-px {
    .ml-#{$i} {
      margin-left: #{$i}px !important;
    }
  }
  @each $i in $margin-right-px {
    .mr-#{$i} {
      margin-right: #{$i}px !important;
    }
  }
}

@include margin;
