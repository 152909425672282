/* use: @include bp-max(sm)... bp-min... bp-only... */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sunflower:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
:root {
  --color-text: #101528;
  --color-black: #000000;
  --color-orange: #ffa500;
  --color-orangeL: #d2a345;
  --color-yellowL: #eeee90;
  --color-yellowLL: #ddcc60;
  --color-darkgrey: #555555;
  --color-grey: #808080;
  --color-greyL: #BAC0CA;
  --color-greyLL: #dfdfdf;
  --background-color: #f1f1f1;
  --color-redLL: #dd8080;
  --color-green: #008000;
  --color-darkgreen: #006400;
  --color-greenL: #9AC193;
  --color-white: #ffffff;
  --color-whiteD: #dbdbdb;
  --color-red: #FF0000;
  --color-redL: #EA4040;
  --color-navyblue: #081444;
  --color-darkgreyblue: #2A2F45;
  --color-darkblue: #484f67;
  --color-blue: #1890FF;
  --color-blueL: #4F74BC;
  --color-blueLL: #8686e7;
  --color-pinkL: #ff4081;
  --color-gold: #b4a27f;
  --color-goldL: #e8c37b;
}

/* base */
/* second font */
/* material icons import */
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  background-color: transparent;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

[hidden] {
  display: none;
}

img {
  display: inline-block;
  vertical-align: top;
}

a {
  text-decoration: none;
}
a:visited {
  color: inherit;
}

/* Add needed sized separeted by space: $margin-px: 0 10 20 30; */
/* Generators */
.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-42 {
  margin-top: 42px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

/* Add needed sized separeted by space: $padding-px: 0 10 20 30; */
/* Generators */
.p-0 {
  padding: 0px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-2 {
  padding-right: 2px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

/* Text align */
.ta-l {
  text-align: left !important;
}

.ta-c {
  text-align: center !important;
}

.ta-r {
  text-align: right !important;
}

/* white space */
.ws-nw {
  white-space: nowrap !important;
}

.ws-pl {
  white-space: pre-line !important;
}

.ws-n {
  white-space: normal !important;
}

.wb-bw {
  word-break: break-word !important;
}

/* font weight */
.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-28 {
  font-size: 28px !important;
}

/* font weight */
.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-b {
  font-weight: bold !important;
}

/* text-transform */
.tt-u {
  text-transform: uppercase !important;
}

.tt-c {
  text-transform: capitalize !important;
}

/* text-decoration */
.td-u {
  text-decoration: underline !important;
}

.text-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-link {
  cursor: pointer !important;
  text-decoration: underline !important;
}

.pointer {
  cursor: pointer !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}

.capitalize-first {
  text-transform: lowercase;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

/* Add needed sized separeted by space: $width: 0 10 20 30; */
/* Generators */
.w-0 {
  width: 0px !important;
  min-width: 0px !important;
}

.w-28 {
  width: 28px !important;
  min-width: 28px !important;
}

.w-30 {
  width: 30px !important;
  min-width: 30px !important;
}

.w-32 {
  width: 32px !important;
  min-width: 32px !important;
}

.w-54 {
  width: 54px !important;
  min-width: 54px !important;
}

.w-72 {
  width: 72px !important;
  min-width: 72px !important;
}

.w-96 {
  width: 96px !important;
  min-width: 96px !important;
}

.w-100 {
  width: 100px !important;
  min-width: 100px !important;
}

.w-128 {
  width: 128px !important;
  min-width: 128px !important;
}

.w-150 {
  width: 150px !important;
  min-width: 150px !important;
}

.w-172 {
  width: 172px !important;
  min-width: 172px !important;
}

.w-184 {
  width: 184px !important;
  min-width: 184px !important;
}

.w-200 {
  width: 200px !important;
  min-width: 200px !important;
}

.w-300 {
  width: 300px !important;
  min-width: 300px !important;
}

.w-400 {
  width: 400px !important;
  min-width: 400px !important;
}

.wp-0 {
  width: 0% !important;
  max-width: 0% !important;
}

.wp-4 {
  width: 4% !important;
  max-width: 4% !important;
}

.wp-5 {
  width: 5% !important;
  max-width: 5% !important;
}

.wp-8 {
  width: 8% !important;
  max-width: 8% !important;
}

.wp-10 {
  width: 10% !important;
  max-width: 10% !important;
}

.wp-11 {
  width: 11% !important;
  max-width: 11% !important;
}

.wp-12 {
  width: 12% !important;
  max-width: 12% !important;
}

.wp-15 {
  width: 15% !important;
  max-width: 15% !important;
}

.wp-20 {
  width: 20% !important;
  max-width: 20% !important;
}

.wp-25 {
  width: 25% !important;
  max-width: 25% !important;
}

.wp-30 {
  width: 30% !important;
  max-width: 30% !important;
}

.wp-33 {
  width: 33% !important;
  max-width: 33% !important;
}

.wp-35 {
  width: 35% !important;
  max-width: 35% !important;
}

.wp-40 {
  width: 40% !important;
  max-width: 40% !important;
}

.wp-45 {
  width: 45% !important;
  max-width: 45% !important;
}

.wp-48 {
  width: 48% !important;
  max-width: 48% !important;
}

.wp-50 {
  width: 50% !important;
  max-width: 50% !important;
}

.wp-55 {
  width: 55% !important;
  max-width: 55% !important;
}

.wp-58 {
  width: 58% !important;
  max-width: 58% !important;
}

.wp-60 {
  width: 60% !important;
  max-width: 60% !important;
}

.wp-75 {
  width: 75% !important;
  max-width: 75% !important;
}

.wp-80 {
  width: 80% !important;
  max-width: 80% !important;
}

.wp-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.h-0 {
  height: 0px !important;
}

.h-20 {
  height: 20px !important;
}

.h-21 {
  height: 21px !important;
}

.h-28 {
  height: 28px !important;
}

.h-30 {
  height: 30px !important;
}

.h-32 {
  height: 32px !important;
}

.h-36 {
  height: 36px !important;
}

.h-40 {
  height: 40px !important;
}

.h-50 {
  height: 50px !important;
}

.hp-0 {
  height: 0% !important;
}

.hp-10 {
  height: 10% !important;
}

.hp-25 {
  height: 25% !important;
}

.hp-30 {
  height: 30% !important;
}

.hp-35 {
  height: 35% !important;
}

.hp-40 {
  height: 40% !important;
}

.hp-50 {
  height: 50% !important;
}

.hp-75 {
  height: 75% !important;
}

.hp-100 {
  height: 100% !important;
}

.lh-16 {
  line-height: 16px !important;
}

.lh-22 {
  line-height: 22px !important;
}

.lh-32 {
  line-height: 32px !important;
}

.lh-36 {
  line-height: 36px !important;
}

/* position */
.pos-rel {
  position: relative !important;
}

.pos-abs {
  position: absolute !important;
}

/* overflow */
.of-hide {
  overflow: hidden !important;
}

.ofx-hide {
  overflow-x: hidden !important;
}

.ofy-hide {
  overflow-y: hidden !important;
}

.op-03 {
  opacity: 0.3;
}

.op-04 {
  opacity: 0.4;
}

.op-05 {
  opacity: 0.5;
}

.op-06 {
  opacity: 0.6;
}

.op-07 {
  opacity: 0.7;
}

.chevron {
  position: sticky;
  bottom: 30px;
  width: 100%;
  height: 0;
  z-index: 2;
}
.chevron__btn {
  position: absolute !important;
  bottom: 40px;
  right: 40px;
  padding: 0;
  border: none;
  background-color: rgba(0, 0, 0, 0.6705882353);
  width: 50px;
  height: 50px;
  z-index: 2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.chevron__btn * {
  color: var(--color-white);
}

.of-visible {
  overflow: visible !important;
}

.ofx-visible {
  overflow-x: visible !important;
}

.ofy-visible {
  overflow-y: visible !important;
}

.of-hidden {
  overflow: hidden !important;
}

.ofx-hidden {
  overflow-x: hidden !important;
}

.ofy-hidden {
  overflow-y: hidden !important;
}

.of-scroll {
  overflow: scroll !important;
}

.ofx-scroll {
  overflow-x: scroll !important;
}

.ofy-scroll {
  overflow-y: scroll !important;
}

.of-auto {
  overflow: auto !important;
}

.ofx-auto {
  overflow-x: auto !important;
}

.ofy-auto {
  overflow-y: auto !important;
}

.of-inherit {
  overflow: inherit !important;
}

.ofx-inherit {
  overflow-x: inherit !important;
}

.ofy-inherit {
  overflow-y: inherit !important;
}

.of-clip {
  overflow: clip !important;
}

.ofx-clip {
  overflow-x: clip !important;
}

.ofy-clip {
  overflow-y: clip !important;
}

.of-initial {
  overflow: initial !important;
}

.ofx-initial {
  overflow-x: initial !important;
}

.ofy-initial {
  overflow-y: initial !important;
}

.of-overlay {
  overflow: overlay !important;
}

.ofx-overlay {
  overflow-x: overlay !important;
}

.ofy-overlay {
  overflow-y: overlay !important;
}

.of-revert {
  overflow: revert !important;
}

.ofx-revert {
  overflow-x: revert !important;
}

.ofy-revert {
  overflow-y: revert !important;
}

.of-unset {
  overflow: unset !important;
}

.ofx-unset {
  overflow-x: unset !important;
}

.ofy-unset {
  overflow-y: unset !important;
}

/* blocks */
.d-b {
  display: block !important;
}

.d-ib {
  display: inline-block !important;
}

/* flexbox */
.d-f {
  display: flex !important;
}

.fd-c {
  flex-direction: column !important;
}

.fd-r {
  flex-direction: row !important;
}

.jc-sb {
  justify-content: space-between !important;
}

.jc-sa {
  justify-content: space-around !important;
}

.jc-se {
  justify-content: space-evenly !important;
  align-content: center !important;
}

.jc-fs {
  justify-content: flex-start !important;
}

.jc-c {
  justify-content: center !important;
}

.jc-fe {
  justify-content: flex-end !important;
}

.js-fs {
  justify-self: flex-start !important;
}

.js-c {
  justify-self: center !important;
}

.js-fe {
  justify-self: flex-end !important;
}

.ai-c {
  align-items: center !important;
}

.ai-fe {
  align-items: flex-end !important;
}

.ai-fs {
  align-items: flex-start !important;
}

.ai-sb {
  align-items: space-between !important;
}

.as-c {
  align-self: center !important;
}

.as-fe {
  align-self: flex-end !important;
}

.as-fs {
  align-self: flex-start !important;
}

.as-sb {
  align-self: space-between !important;
}

.ac-c {
  align-content: center;
}

.fg-1 {
  flex-grow: 1 !important;
}

.flex {
  flex: 1 1 0%;
  box-sizing: border-box;
}

.f-1-0-50 {
  display: flex !important;
  flex: 1 0 50% !important;
}

.f-1-1-100 {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: 100%;
}

html,
*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  outline: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 14px;
}

body {
  font-family: "Roboto", sans-serif;
  color: var(--color-text);
  line-height: 1.4;
  position: relative;
}

.row {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
}
.row._nowrap {
  flex-wrap: nowrap !important;
}

.col {
  display: flex !important;
  flex-direction: column !important;
}

.col-5 {
  width: 20%;
  padding: 0 10px 10px;
}
@media (max-width: 767.9px) {
  .col-5 {
    width: 100%;
  }
}

.col-4 {
  width: 25%;
  padding: 0 10px 10px;
}
@media (max-width: 767.9px) {
  .col-4 {
    width: 100%;
  }
}

.col-3 {
  width: 33.33%;
  padding: 0 10px 10px;
}
@media (max-width: 767.9px) {
  .col-3 {
    width: 100%;
  }
}

.col-25 {
  width: 40%;
  padding: 0 10px 10px;
}
@media (max-width: 767.9px) {
  .col-25 {
    width: 100%;
  }
}

.col-2 {
  width: 50%;
  padding: 0 10px 10px;
}
@media (max-width: 767.9px) {
  .col-2 {
    width: 100%;
  }
}

.col-2m {
  width: 50%;
  padding: 0 10px 10px;
}

.col-23 {
  width: 66.66%;
  padding: 0 10px 10px;
}
@media (max-width: 767.9px) {
  .col-23 {
    width: 100%;
  }
}

.col-1 {
  width: 100%;
  padding: 0 10px 10px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #101528;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #b4a27f;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #101528;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #101528;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b4a27f;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b4a27f;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b4a27f;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b4a27f;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #101528;
  --mdc-linear-progress-track-color: rgba(16, 21, 40, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(16, 21, 40, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(16, 21, 40, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(16, 21, 40, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(16, 21, 40, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(16, 21, 40, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #b4a27f;
  --mdc-linear-progress-track-color: rgba(180, 162, 127, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(180, 162, 127, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(180, 162, 127, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(180, 162, 127, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(180, 162, 127, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(180, 162, 127, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-caret-color: #101528;
  --mdc-filled-text-field-focus-active-indicator-color: #101528;
  --mdc-filled-text-field-focus-label-text-color: rgba(16, 21, 40, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #101528;
  --mdc-outlined-text-field-focus-outline-color: #101528;
  --mdc-outlined-text-field-focus-label-text-color: rgba(16, 21, 40, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(16, 21, 40, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(180, 162, 127, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #b4a27f;
  --mdc-filled-text-field-focus-active-indicator-color: #b4a27f;
  --mdc-filled-text-field-focus-label-text-color: rgba(180, 162, 127, 0.87);
  --mdc-outlined-text-field-caret-color: #b4a27f;
  --mdc-outlined-text-field-focus-outline-color: #b4a27f;
  --mdc-outlined-text-field-focus-label-text-color: rgba(180, 162, 127, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(16, 21, 40, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(180, 162, 127, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #101528;
  --mdc-chip-elevated-disabled-container-color: #101528;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: #b4a27f;
  --mdc-chip-elevated-disabled-container-color: #b4a27f;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #0e1224;
  --mdc-switch-selected-handle-color: #0e1224;
  --mdc-switch-selected-hover-state-layer-color: #0e1224;
  --mdc-switch-selected-pressed-state-layer-color: #0e1224;
  --mdc-switch-selected-focus-handle-color: #05060f;
  --mdc-switch-selected-hover-handle-color: #05060f;
  --mdc-switch-selected-pressed-handle-color: #05060f;
  --mdc-switch-selected-focus-track-color: #585b69;
  --mdc-switch-selected-hover-track-color: #585b69;
  --mdc-switch-selected-pressed-track-color: #585b69;
  --mdc-switch-selected-track-color: #585b69;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d1a052;
  --mdc-switch-selected-handle-color: #d1a052;
  --mdc-switch-selected-hover-state-layer-color: #d1a052;
  --mdc-switch-selected-pressed-state-layer-color: #d1a052;
  --mdc-switch-selected-focus-handle-color: #bc7d2e;
  --mdc-switch-selected-hover-handle-color: #bc7d2e;
  --mdc-switch-selected-pressed-handle-color: #bc7d2e;
  --mdc-switch-selected-focus-track-color: #e2c28c;
  --mdc-switch-selected-hover-track-color: #e2c28c;
  --mdc-switch-selected-pressed-track-color: #e2c28c;
  --mdc-switch-selected-track-color: #e2c28c;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #101528;
  --mdc-radio-selected-hover-icon-color: #101528;
  --mdc-radio-selected-icon-color: #101528;
  --mdc-radio-selected-pressed-icon-color: #101528;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #101528;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #b4a27f;
  --mdc-radio-selected-hover-icon-color: #b4a27f;
  --mdc-radio-selected-icon-color: #b4a27f;
  --mdc-radio-selected-pressed-icon-color: #b4a27f;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #b4a27f;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #101528;
  --mdc-slider-focus-handle-color: #101528;
  --mdc-slider-hover-handle-color: #101528;
  --mdc-slider-active-track-color: #101528;
  --mdc-slider-inactive-track-color: #101528;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #101528;
  --mat-mdc-slider-ripple-color: #101528;
  --mat-mdc-slider-hover-ripple-color: rgba(16, 21, 40, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(16, 21, 40, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #b4a27f;
  --mdc-slider-focus-handle-color: #b4a27f;
  --mdc-slider-hover-handle-color: #b4a27f;
  --mdc-slider-active-track-color: #b4a27f;
  --mdc-slider-inactive-track-color: #b4a27f;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #b4a27f;
  --mat-mdc-slider-ripple-color: #b4a27f;
  --mat-mdc-slider-hover-ripple-color: rgba(180, 162, 127, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(180, 162, 127, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #101528;
  --mdc-radio-selected-hover-icon-color: #101528;
  --mdc-radio-selected-icon-color: #101528;
  --mdc-radio-selected-pressed-icon-color: #101528;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #b4a27f;
  --mdc-radio-selected-hover-icon-color: #b4a27f;
  --mdc-radio-selected-icon-color: #b4a27f;
  --mdc-radio-selected-pressed-icon-color: #b4a27f;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #101528;
  --mdc-checkbox-selected-hover-icon-color: #101528;
  --mdc-checkbox-selected-icon-color: #101528;
  --mdc-checkbox-selected-pressed-icon-color: #101528;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #101528;
  --mdc-checkbox-selected-hover-state-layer-color: #101528;
  --mdc-checkbox-selected-pressed-state-layer-color: #101528;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #b4a27f;
  --mdc-checkbox-selected-hover-icon-color: #b4a27f;
  --mdc-checkbox-selected-icon-color: #b4a27f;
  --mdc-checkbox-selected-pressed-icon-color: #b4a27f;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #b4a27f;
  --mdc-checkbox-selected-hover-state-layer-color: #b4a27f;
  --mdc-checkbox-selected-pressed-state-layer-color: #b4a27f;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #101528;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #101528;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #101528;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #101528;
  --mat-tab-header-active-ripple-color: #101528;
  --mat-tab-header-inactive-ripple-color: #101528;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #101528;
  --mat-tab-header-active-hover-label-text-color: #101528;
  --mat-tab-header-active-focus-indicator-color: #101528;
  --mat-tab-header-active-hover-indicator-color: #101528;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #b4a27f;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #b4a27f;
  --mat-tab-header-active-ripple-color: #b4a27f;
  --mat-tab-header-inactive-ripple-color: #b4a27f;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #b4a27f;
  --mat-tab-header-active-hover-label-text-color: #b4a27f;
  --mat-tab-header-active-focus-indicator-color: #b4a27f;
  --mat-tab-header-active-hover-indicator-color: #b4a27f;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #101528;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #b4a27f;
  --mat-tab-header-with-background-foreground-color: black;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #b4a27f;
  --mdc-checkbox-selected-hover-icon-color: #b4a27f;
  --mdc-checkbox-selected-icon-color: #b4a27f;
  --mdc-checkbox-selected-pressed-icon-color: #b4a27f;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #b4a27f;
  --mdc-checkbox-selected-hover-state-layer-color: #b4a27f;
  --mdc-checkbox-selected-pressed-state-layer-color: #b4a27f;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #101528;
  --mdc-checkbox-selected-hover-icon-color: #101528;
  --mdc-checkbox-selected-icon-color: #101528;
  --mdc-checkbox-selected-pressed-icon-color: #101528;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #101528;
  --mdc-checkbox-selected-hover-state-layer-color: #101528;
  --mdc-checkbox-selected-pressed-state-layer-color: #101528;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #101528;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #b4a27f;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #101528;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #b4a27f;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #101528;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #b4a27f;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #101528;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #b4a27f;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #101528;
  --mat-mdc-button-ripple-color: rgba(16, 21, 40, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #b4a27f;
  --mat-mdc-button-ripple-color: rgba(180, 162, 127, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #101528;
  --mat-mdc-button-persistent-ripple-color: #101528;
  --mat-mdc-button-ripple-color: rgba(16, 21, 40, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #b4a27f;
  --mat-mdc-button-persistent-ripple-color: #b4a27f;
  --mat-mdc-button-ripple-color: rgba(180, 162, 127, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #101528;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #b4a27f;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #b4a27f;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #101528;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #b4a27f;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #101528;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #b4a27f;
  --mat-badge-text-color: black;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #101528;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(16, 21, 40, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(16, 21, 40, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(16, 21, 40, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #101528;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(16, 21, 40, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #b4a27f;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(180, 162, 127, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(180, 162, 127, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(180, 162, 127, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(180, 162, 127, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #b4a27f;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #101528;
}
.mat-icon.mat-accent {
  --mat-icon-color: #b4a27f;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #101528;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #101528;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #101528;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: #b4a27f;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: #b4a27f;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: #b4a27f;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #101528;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #b4a27f;
  --mat-toolbar-container-text-color: black;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

/* ANGULAR MATERIAL OVERRIDE */
mat-form-field {
  font-size: 14px;
  position: relative;
  margin: 6px 0;
}
mat-form-field textarea {
  margin: 5px 0 !important;
}
mat-form-field .mdc-text-field {
  border-radius: 4px;
}
mat-form-field .mat-mdc-form-field-flex {
  min-height: 38px !important;
}
mat-form-field .mat-mdc-form-field-infix {
  width: auto !important;
  min-height: 20px !important;
  padding: 0 !important;
  margin: auto 0 !important;
}
mat-form-field .mat-mdc-floating-label {
  top: 50% !important;
}
mat-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  top: 28px !important;
}
mat-form-field .mat-mdc-form-field-subscript-wrapper {
  position: absolute !important;
  top: 100% !important;
}
mat-form-field .mat-mdc-form-field-icon-suffix {
  display: flex;
}
mat-form-field .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button.mat-mdc-button-base {
  width: 36px;
  height: 36px;
}
mat-form-field .mat-mdc-form-field-icon-prefix > .mat-icon,
mat-form-field .mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 0;
}
mat-form-field.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper, mat-form-field.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
mat-form-field.mat-form-field-appearance-fill .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
mat-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}
mat-form-field .mat-mdc-form-field-hint-wrapper, mat-form-field .mat-mdc-form-field-error-wrapper {
  padding: 0 12px !important;
}
mat-form-field .mat-mdc-form-field-bottom-align::before {
  height: 12px !important;
}
mat-form-field .mat-mdc-table .mdc-data-table__header-row {
  font-size: 14px !important;
}
mat-form-field tr.mat-mdc-header-row {
  font-size: 14px !important;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100% !important;
}

.mdc-list-item__content {
  overflow: visible !important;
}

.mat-badge-content {
  font-size: 12px;
}

.mat-mdc-menu-item .mdc-list-item__primary-text, .mat-mdc-menu-item-text {
  width: 100%;
  display: flex;
  align-items: center;
}

.mat-mdc-menu-item .mat-icon {
  overflow: visible;
}

.mat-mdc-outlined-button:not(:disabled) {
  border: 1px solid var(--color-text) !important;
  border-radius: 4px;
}
.mat-mdc-outlined-button:not(:disabled).mat-accent {
  border: 1px solid var(--color-gold) !important;
}

.mat-mdc-raised-button:not(.mdc-button-disabled).mat-accent {
  color: var(--color-white);
}

.mdc-icon-button:not(.mat-mdc-paginator-navigation-previous, .mat-mdc-paginator-navigation-next) {
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.mdc-icon-button:not(.mat-mdc-paginator-navigation-previous, .mat-mdc-paginator-navigation-next) svg,
.mdc-icon-button:not(.mat-mdc-paginator-navigation-previous, .mat-mdc-paginator-navigation-next) img {
  width: auto !important;
  height: auto !important;
}

.mdc-button__label {
  display: flex;
  align-items: center;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-drawer-inner-container {
  padding: 0 !important;
}

.cdk-global-scrollblock {
  overflow: hidden;
  position: static;
}

.no-padding-dialog .mat-mdc-dialog-container {
  padding: 0;
  height: initial;
}

@media screen and (max-width: 768px) {
  .mob-full-dialog {
    width: 100% !important;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 992px) {
  .mob-overflow {
    overflow: hidden;
  }
}
.event {
  margin: 21px !important;
  padding: 10px 16px !important;
}
.event.mat-mdc-snack-bar-container {
  max-width: 100% !important;
}

.scroll-not-safari {
  overscroll-behavior-y: none;
}
.scroll-not-safari body {
  overscroll-behavior-y: none;
}
.scroll-not-safari ::-webkit-scrollbar {
  max-width: 11px;
}

::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 34px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

._oa:after {
  content: "OA";
  border: 1px solid black;
  padding: 1px 1px 0 1px;
  margin-left: 5px;
  font-size: 12px;
}

.fill-gold {
  filter: brightness(0) saturate(100%) invert(67%) sepia(21%) saturate(426%) hue-rotate(1deg) brightness(93%) contrast(92%);
}

.fill-grey {
  filter: brightness(0) opacity(30%);
}

@media screen and (max-width: 768px) {
  app-root, .cdk-describedby-message-container, .cdk-overlay-container {
    zoom: 95%;
  }
}
@media screen and (max-width: 576px) {
  app-root, .cdk-describedby-message-container, .cdk-overlay-container {
    zoom: 90%;
  }
}

.install-alerted app-shell {
  min-height: calc(100% - 65px);
}
@media screen and (max-width: 768px) {
  .install-alerted app-shell {
    min-height: calc(100% - 103px);
  }
}
.install-alerted app-shell .flight-requests {
  min-height: calc(100dvh - 201px);
}
@media screen and (max-width: 768px) {
  .install-alerted app-shell .flight-requests {
    min-height: auto;
  }
}

@media screen and (max-width: 576px) {
  .flag {
    zoom: 111.1111111111%;
  }
}

* {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.modal-top {
  border-bottom: solid 1px #DBDBDB;
  padding: 20px 30px;
}
.modal-top__title {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
}
.modal-container {
  padding: 30px;
  width: 100%;
}

.modal__inner {
  display: flex;
  width: 100%;
  padding-top: 5px;
}
.modal__inner-left {
  display: flex;
  width: 100%;
}
.modal__inner-right {
  display: flex;
  width: 100%;
}

.modal-bottom {
  padding: 14px 30px;
  display: flex;
  justify-content: flex-end;
  border-top: solid 1px #DBDBDB;
}
.modal-bottom__btn {
  width: 150px;
  height: 30px;
  cursor: pointer;
}

.bdrop {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 20px;
  border-bottom: 1px solid rgba(16, 21, 40, 0.3);
}
.filters__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-right: 5px;
}
.filters__daterangepicker {
  max-width: 220px;
}

.list-container {
  padding: 10px 20px;
}
.list-container .mat-expansion-panel .expand-icon {
  margin-right: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transform-origin: center;
  z-index: 2;
}
.list-container .mat-expansion-panel .expand-icon._active {
  transform: rotate(90deg);
}
.list-container .mat-expansion-panel.mat-expanded .is-exp-icon .expand-icon {
  transform: rotate(90deg);
}
.list-container .mat-expansion-panel.no-shadow {
  box-shadow: none !important;
}
.list-container .mat-expansion-panel .mat-expansion-panel-header.mat-mdc-row {
  height: auto !important;
  min-height: 72px !important;
}
.list-container .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
}
.list-container .mat-expansion-panel section {
  display: flex;
}
.list-container .mat-expansion-panel td.mat-mdc-cell {
  border-top: 2px solid rgba(16, 21, 40, 0.2);
  padding-right: 5px;
}
.list-container .mat-expansion-panel tr.mat-mdc-row {
  height: 74px !important;
}
.list-container .mat-expansion-panel .mat-mdc-cell, .list-container .mat-expansion-panel .mat-mdc-header-cell {
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word !important;
  white-space: unset !important;
  overflow-wrap: break-word;
  border-bottom-style: none !important;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.list-container .mat-expansion-panel .icao {
  max-width: 100px;
  background: rgba(16, 21, 40, 0.2);
  border-radius: 26px;
  padding: 6px 10px;
  margin-right: 5px;
  white-space: nowrap;
}
.list-container .mat-expansion-panel .icao .icao-date {
  font-weight: 300;
  font-size: 17px;
  letter-spacing: -0.41px;
  color: var(--color-black);
  opacity: 0.5;
}
.list-container .mat-expansion-panel .flight-date {
  font-family: "Sunflower", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.771846px;
  color: var(--color-text);
  display: block;
}
.list-container .mat-expansion-panel .flight-date-wd {
  font-weight: 300;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
}
.list-container .mat-expansion-panel .created-date {
  font-family: "Sunflower", sans-serif;
  font-weight: 500;
  font-size: 12px;
  opacity: 0.5;
  letter-spacing: -0.2px;
}
.list-container .mat-expansion-panel .fi {
  margin-right: 5px;
  min-width: 22px;
  height: 14px;
  background-size: cover !important;
}
.list-container .mat-expansion-panel .from-to-block {
  font-family: Sunflower, sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.24px;
}
.list-container .mat-expansion-panel .from-to-block__name {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 16vw;
}
.list-container .mat-expansion-panel .from-to-block__country {
  opacity: 0.5;
}
.list-container .mat-expansion-panel .budget-total {
  color: var(--color-black);
  min-width: 120px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.list-container .mat-expansion-panel .price {
  font-size: 20px;
}
.list-container .mat-expansion-panel .price.zero {
  opacity: 0.5;
  font-size: 18px;
}
.list-container .mat-expansion-panel .lh36 {
  line-height: 36px;
}
.list-container .mat-expansion-panel .status-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-text);
}
.list-container .mat-expansion-panel .status-text.quiet {
  opacity: 0.6;
}
.list-container .mat-expansion-panel .btn-icon span {
  flex-grow: 1;
  text-align: center;
}
.list-container table.mat-mdc-table {
  width: 100%;
  min-width: unset !important;
}
.list-container tr.mat-mdc-header-row {
  height: 42px !important;
}
.list-container .offers-total {
  text-align: right;
  padding-right: 24px;
  border: none !important;
  background: var(--background-color);
}
.list-container .offers-total__title {
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-text);
  opacity: 0.6;
}
.list-container .offers-total__title.quiet {
  opacity: 0.4;
  text-decoration: none;
  pointer-events: none;
}
.list-container .offer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 38px;
  margin: 1px;
}
.list-container .offer-canceled {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1;
  top: 0;
  left: 0;
}
.list-container .offer-canceled__title {
  width: 272px;
  font-weight: 500;
  font-size: 16px;
  color: rgba(16, 21, 40, 0.6666666667);
  padding: 8px 18px;
  box-shadow: 0px 2px 8px 3px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(16, 21, 40, 0.6666666667);
  outline: 3px solid var(--background-color);
  border-spacing: 2px;
  background: var(--background-color);
}
.list-container .mat-action-row {
  padding: 18px 24px;
}
.list-container .empty-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 32px;
  color: var(--color-grey);
}

.passengers-block {
  max-width: 120px;
  height: 42px;
  border: 1px solid var(--color-text);
  align-content: center;
  text-align: center;
}
.passengers-block .passengers-icon {
  width: 100%;
}

.custom-tooltip {
  margin-bottom: -20px !important;
  padding: 5px 20px 5px 20px !important;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}
.custom-tooltip.__new {
  background-color: var(--color-greenL) !important;
}
.custom-tooltip.__cancelled {
  background-color: var(--color-yellowL) !important;
  color: var(--color-black);
}
.custom-tooltip.__lost {
  background-color: var(--color-blueLL) !important;
}
.custom-tooltip.__expired {
  background-color: var(--color-grey) !important;
}
.custom-tooltip.__banned {
  background-color: var(--color-redLL) !important;
}
.custom-tooltip.__landed {
  background-color: var(--color-blueL) !important;
}

.profile-wrapper {
  width: 100%;
}
.profile-wrapper .info {
  width: 100%;
  background: var(--color-white);
  padding: 15px 30px;
}
.profile-wrapper .info.nopad {
  padding: 0;
}
.profile-wrapper .info .info-name {
  font-size: 22px;
  line-height: 30px;
  color: var(--color-black);
}
.profile-wrapper .info .info-status {
  font-style: italic;
  font-size: 22px;
  line-height: 30px;
  color: var(--color-grey);
}
.profile-wrapper .info .negative-status {
  font-style: italic;
  font-size: 22px;
  line-height: 30px;
  color: var(--color-red);
}
.profile-wrapper .info ._active {
  color: var(--color-green);
}
.profile-wrapper .info .info-license {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-text);
}
.profile-wrapper .info .info-docs {
  padding-right: 10px;
}
.profile-wrapper .info .info-docs__name {
  margin-bottom: 10px;
  word-break: break-word;
}
.profile-wrapper .info .info-docs__btn {
  margin-bottom: 10px;
}
.profile-wrapper .info .info-docs__title {
  cursor: pointer;
  text-decoration: underline;
}
.profile-wrapper .info__right {
  min-width: 300px;
  background: var(--color-white);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 450px) {
  .profile-wrapper .info__right {
    min-width: 100%;
  }
}
.profile-wrapper .info__right .right-head {
  font-weight: 500;
  font-size: 16px;
  height: 48px;
  padding: 8px 15px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.profile-wrapper .info__right .right-list {
  position: relative;
}
.profile-wrapper .info__right .right-list .scroll {
  max-height: 265px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.profile-wrapper .info__right .right-list .scroll .empl-item {
  color: var(--color-text);
  padding: 4px 15px;
}
.profile-wrapper .info__right .right-list .scroll .empl-item:hover {
  cursor: pointer;
  background: rgba(16, 21, 40, 0.0862745098);
}
.profile-wrapper .info__right .right-list .scroll .empl-item .my-empl {
  color: rgb(210, 163, 69);
}
.profile-wrapper .middle {
  height: 40%;
}
.profile-wrapper .middle .action {
  margin-top: 15px;
  padding: 28px 30px;
  background-color: var(--color-white);
}
.profile-wrapper .middle .action__title {
  font-size: 22px;
  line-height: 26px;
  color: var(--color-black);
}
.profile-wrapper .middle .action__btn {
  width: 128px;
  height: 32px;
}
.profile-wrapper .bottom {
  position: fixed;
  bottom: 0;
  background: var(--color-white);
  min-height: 180px;
  width: 100%;
  border-top: 1px solid rgba(16, 21, 40, 0.3);
}
.profile-wrapper .bottom__section {
  padding: 24px 30px;
  min-width: 180px;
}
.profile-wrapper .bottom__title {
  height: 44px;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-text);
}
.profile-wrapper .bottom .content-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: var(--color-black);
}

.request-menu .mat-expansion-panel-header {
  padding: 0 0 0 24px;
}
.request-menu .mat-expansion-panel-header-description {
  flex-grow: 0;
  margin-right: 10px !important;
  white-space: nowrap;
}

.widget {
  margin-bottom: 5px !important;
}
.widget__header {
  height: auto !important;
  min-height: 48px;
  margin: 0 4px 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--color-darkblue) !important;
}
.widget__header .mat-content {
  align-items: center;
}
.widget__header .expand-icon {
  display: flex;
  align-items: center;
  margin-right: 15px;
  transition: 0.3s;
  transform-origin: center;
  padding: 2px 2px 4px;
}
.widget__header .title {
  display: flex;
  align-items: center;
  color: var(--color-white);
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.widget__header .description {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 0;
  font-size: 12px;
  flex-grow: 1;
}
.widget__header .description__first {
  color: var(--color-gold);
}
.widget__header .description__second {
  color: rgba(237, 237, 237, 0.5);
  margin-left: 10px;
}
.widget__header .description__link {
  text-decoration: underline;
  color: var(--color-goldL);
}
.widget__header .description__balance {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--color-gold);
}
.widget__header .menu {
  padding-bottom: 2px;
  margin-left: 5px;
}
.widget__body.new-request {
  margin: 10px;
}
.widget__body.new-request .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--color-gold);
  margin-bottom: 5px;
}
.widget__body.new-request .fi {
  margin-right: 15px;
  height: 20px;
  width: 27px;
}
.widget__body.new-request .new-request-icao {
  display: flex;
  align-items: center;
  height: 40px;
  overflow: hidden;
}
.widget__body.new-request .new-request-icao span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.widget__body.new-request .new-request-passenger {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget__body.new-request .new-request-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widget__body.new-request .new-request-field .text1 {
  line-height: 18px;
  letter-spacing: -0.24px;
}
.widget__body.new-request .new-request-field .text2 {
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-gold);
}
.widget__body.request {
  margin: 10px;
}
.widget__body.request .request-datetime {
  min-width: 98px;
  height: 98px;
  padding: 6px;
  border-radius: 3px;
  color: var(--color-white);
  background-color: var(--color-gold);
  text-align: center;
  font-size: 12px;
}
.widget__body.request .request-datetime__day {
  font-size: 26px;
  line-height: 36px;
}
.widget__body.request .request-datetime__month {
  margin-bottom: 6px;
  letter-spacing: 0.4px;
}
.widget__body.request .request-datetime__time {
  padding-top: 5px;
  border-top: 1px solid var(--color-white);
  letter-spacing: 0.4px;
}
.widget__body.request .request-icao {
  display: flex;
  align-items: center;
  height: 45px;
  overflow: hidden;
}
.widget__body.request .request-icao span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.widget__body.request .request-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.widget__body.request .request-field__name {
  margin-right: 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--color-gold);
}
.widget__body.request .request-field__budget {
  display: flex;
  align-items: center;
  height: 36px;
  max-width: 200px;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--color-navyblue);
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
}
.widget__body.request .request-field__budget.grey {
  color: var(--color-grey);
}
.widget__body.request .request-field__passengers {
  display: flex;
  align-items: center;
  height: 36px;
  max-width: 200px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
}
.widget__body.request .request-field__passengers span {
  margin-left: 10px;
  font-weight: 300;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-navyblue);
}
.widget__body.request .request-category {
  position: relative;
  display: flex;
  margin: 30px 0 10px 0;
}
.widget__body.request .request-category .segment {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 25%;
  color: var(--color-gold);
  border-top: 2px solid var(--color-gold);
}
.widget__body.request .request-category .segment:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 4px;
  width: 50%;
  border-right: 2px solid var(--color-gold);
}
.widget__body.request .request-category .segment:first-of-type:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  width: 0;
  border-right: 2px solid var(--color-gold);
}
.widget__body.request .request-category .slider {
  position: absolute;
  top: -7px;
  left: 20%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color-gold);
}
.widget__body.featured-deal {
  margin: 10px;
}
.widget__body.featured-deal .featured-deal-datetime {
  margin-right: 15px;
  min-width: 96px;
  padding: 6px;
  border-radius: 3px;
  color: var(--color-white);
  background-color: var(--color-gold);
  text-align: center;
  font-size: 12px;
}
.widget__body.featured-deal .featured-deal-datetime__day {
  padding: 8px 0;
  font-size: 24px;
  line-height: 36px;
}
.widget__body.featured-deal .featured-deal-datetime__month {
  margin: 10px 0;
  letter-spacing: 0.4px;
  font-size: 14px;
}
.widget__body.featured-deal .featured-deal-datetime__time {
  padding-top: 10px;
  letter-spacing: 0.4px;
}
.widget__body.featured-deal .featured-deal-datetime__divider {
  border-top: 1.5px dashed var(--color-white);
}
.widget__body.featured-deal .featured-deal-icao {
  width: 100%;
  overflow: hidden;
}
.widget__body.featured-deal .featured-deal-icao__title {
  font-size: 12px;
  color: var(--color-gold);
}
.widget__body.featured-deal .featured-deal-icao__value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 10px;
  border-radius: 4px;
  border: 1px solid var(--color-gold);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.widget__body.featured-deal .featured-deal-icao__value .pin {
  padding-right: 5px;
  filter: brightness(0%);
}
.widget__body.featured-deal .featured-deal-icao__value .country {
  justify-self: flex-end;
}
.widget__body.featured-deal .featured-deal-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.widget__body.featured-deal .featured-deal-field__name {
  margin-right: 10px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--color-gold);
}
.widget__body.featured-deal .featured-deal-field__budget {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 36px;
  max-width: 200px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--color-navyblue);
}
.widget__body.featured-deal .featured-deal-field__budget.grey {
  color: var(--color-grey);
}
.widget__body.featured-deal .featured-deal-field__passengers {
  display: flex;
  align-items: center;
  height: 36px;
  max-width: 200px;
}
.widget__body.featured-deal .featured-deal-field__passengers span {
  margin-left: 10px;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-navyblue);
}
.widget__body .aircraft {
  display: flex;
}
.widget__body .aircraft__logo {
  margin-right: 20px;
  min-width: 126px;
  width: 126px;
  height: 82px;
  overflow: hidden;
  position: relative;
}
.widget__body .aircraft__logo img {
  object-fit: cover;
  min-width: 126px;
  width: 126px;
  height: 82px;
}
.widget__body .aircraft .gold {
  color: var(--color-gold);
}
.widget__body .aircraft .link {
  color: var(--color-blue);
  text-decoration: underline;
}
.widget__body .aircraft__oa {
  border: 1px solid #2A2F45;
}
.widget__body .link-btn {
  background-color: var(--color-gold);
  color: var(--color-white);
}
.widget__field {
  padding: 5px 10px;
  flex-grow: 1;
}
.widget__field .title {
  font-size: 14px;
  line-height: 28px;
  max-height: 28px;
  color: var(--color-gold);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget__field .title .edit-phone {
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.widget__field .fl-cap::first-letter {
  text-transform: capitalize;
}
.widget__field .value {
  position: relative;
  padding: 9px 0;
  font-size: 16px;
  min-height: 37px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: var(--color-navyblue);
}
.widget__field .value .call-btn {
  background-color: var(--color-gold);
  color: var(--color-white);
  height: 24px;
  font-size: 14px;
}
.widget__field .sub-value {
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  padding: 5px;
}
.widget__field .icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 0;
}
.widget__field .icon button {
  margin-bottom: -4px;
}
.widget__field .value::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #fff 40%, var(--color-navyblue) 40%);
  background-size: 8px 1px;
  background-repeat: repeat-x;
}
.widget__field .value:empty::before {
  background-image: linear-gradient(to right, #fff 40%, var(--color-gold) 40%);
  background-size: 6px 2px;
  background-repeat: repeat-x;
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.widget mgl-map {
  display: block;
  height: 180px;
  width: 100%;
}
.widget .mat-expanded .expand-icon {
  transform: rotate(90deg);
}