.request-menu {
    .mat-expansion-panel-header {
      padding: 0 0 0 24px;
    }
    .mat-expansion-panel-header-description {
      flex-grow: 0;
      margin-right: 10px !important;
      white-space: nowrap;
    }
}
.widget {
  margin-bottom: 5px !important;
  &__header {
    height: auto !important;
    min-height: 48px;
    margin: 0 4px 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: var(--color-darkblue) !important;
    .mat-content {
      align-items: center;
    }
    .expand-icon {
      display: flex;
      align-items: center;
      margin-right: 15px;
      transition: 0.3s;
      transform-origin: center;
      padding: 2px 2px 4px;
    }
    .title {
      display: flex;
      align-items: center;
      color: var(--color-white);
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
    .description {
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      margin-right: 0;
      font-size: 12px;
      flex-grow: 1;
      &__first {
        color: var(--color-gold);
      }
      &__second {
        color: rgba(237, 237, 237, 0.5);
        margin-left: 10px;
      }
      &__link {
        text-decoration: underline;
        color: var(--color-goldL);
      }
      &__balance {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: var(--color-gold);
      }
    }
    .menu {
      padding-bottom: 2px;
      margin-left: 5px;
    }
  }
  &__body {
    &.new-request {
      margin: 10px;
      .title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: var(--color-gold);
        margin-bottom: 5px;
      }
      .fi {
        margin-right: 15px;
        height: 20px;
        width: 27px;
      }
      .new-request-icao {
        display: flex;
        align-items: center;
        height: 40px;
        overflow: hidden;
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .new-request-passenger {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .new-request-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .text1 {
          line-height: 18px;
          letter-spacing: -0.24px;
        }
        .text2 {
          font-size: 12px;
          line-height: 22px;
          letter-spacing: -0.41px;
          color: var(--color-gold);

        }
      }
    }
    &.request {
      margin: 10px;
      .request-datetime {
        min-width: 98px;
        height: 98px;
        padding: 6px;
        border-radius: 3px;
        color: var(--color-white);
        background-color: var(--color-gold);
        text-align: center;
        font-size: 12px;
        &__day {
          font-size: 26px;
          line-height: 36px;
        }
        &__month {
          margin-bottom: 6px;
          letter-spacing: 0.4px;
        }
        &__time {
          padding-top: 5px;
          border-top: 1px solid var(--color-white);
          letter-spacing: 0.4px;
        }
      }
      .request-icao {
        display: flex;
        align-items: center;
        height: 45px;
        overflow: hidden;
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .request-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &__name {
          margin-right: 10px;
          font-weight: bold;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.2px;
          color: var(--color-gold);
        }
        &__budget {
          display: flex;
          align-items: center;
          height: 36px;
          max-width: 200px;
          font-weight: bold;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.2px;
          color: var(--color-navyblue);
          box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
          &.grey {
            color: var(--color-grey)
          }
        }
        &__passengers {
          display: flex;
          align-items: center;
          height: 36px;
          max-width: 200px;
          box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
          span {
            margin-left: 10px;
            font-weight: 300;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: -0.41px;
            color: var(--color-navyblue);
          }
        }
      }
      .request-category {
        position: relative;
        display: flex;
        margin: 30px 0 10px 0;
        .segment {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 25%;
          color: var(--color-gold);
          border-top: 2px solid var(--color-gold);
          &:before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 4px;
            width: 50%;
            border-right: 2px solid var(--color-gold);
          }
          &:first-of-type:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 4px;
            width: 0;
            border-right: 2px solid var(--color-gold);
          }
        }
        .slider {
          position: absolute;
          top: -7px;
          left: 20%;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: var(--color-gold);
        }
      }
    }
    &.featured-deal {
      margin: 10px;
      .featured-deal-datetime {
        margin-right: 15px;
        min-width: 96px;
        padding: 6px;
        border-radius: 3px;
        color: var(--color-white);
        background-color: var(--color-gold);
        text-align: center;
        font-size: 12px;
        &__day {
          padding: 8px 0;
          font-size: 24px;
          line-height: 36px;
        }
        &__month {
          margin: 10px 0;
          letter-spacing: 0.4px;
          font-size: 14px;
        }
        &__time {
          padding-top: 10px;
          letter-spacing: 0.4px;
        }
        &__divider {
          border-top: 1.5px dashed var(--color-white);
        }
      }
      .featured-deal-icao {
        width: 100%;
        overflow: hidden;
        &__title {
          font-size: 12px;
          color: var(--color-gold);
        }
        &__value {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 15px 10px 10px;
          border-radius: 4px;
          border: 1px solid var(--color-gold);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          .pin {
            padding-right: 5px;
            filter: brightness(0%);
          }
          .country {
            justify-self: flex-end;
          }
        }
      }
      .featured-deal-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &__name {
          margin-right: 10px;
          font-weight: bold;
          line-height: 24px;
          letter-spacing: 0.2px;
          color: var(--color-gold);
        }
        &__budget {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 36px;
          max-width: 200px;
          font-weight: bold;
          line-height: 24px;
          letter-spacing: 0.2px;
          color: var(--color-navyblue);
          &.grey {
            color: var(--color-grey)
          }
        }
        &__passengers {
          display: flex;
          align-items: center;
          height: 36px;
          max-width: 200px;
          span {
            margin-left: 10px;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: -0.41px;
            color: var(--color-navyblue);
          }
        }
      }
    }
    .aircraft {
      display: flex;

      &__logo {
        margin-right: 20px;
        min-width: 126px;
        width: 126px;
        height: 82px;
        overflow: hidden;
        position: relative;

        img {
          object-fit: cover;
          min-width: 126px;
          width: 126px;
          height: 82px;
        }
      }
      .gold {
        color: var(--color-gold);
      }
      .link {
        color: var(--color-blue);
        text-decoration: underline;
      }
      &__oa {
        border: 1px solid #2A2F45;
      }
    }
    .link-btn {
      background-color: var(--color-gold);
      color: var(--color-white);
    }
  }
  &__field {
    padding: 5px 10px;
    flex-grow: 1;
    .title {
      font-size: 14px;
      line-height: 28px;
      max-height: 28px;
      color: var(--color-gold);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .edit-phone {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
    }
    .fl-cap::first-letter {
      text-transform: capitalize;
    }
    .value {
      position: relative;
      padding: 9px 0;
      font-size: 16px;
      min-height: 37px;
      line-height: 18px;
      letter-spacing: 0.5px;
      color: var(--color-navyblue);
      .call-btn {
        background-color: var(--color-gold);
        color: var(--color-white);
        height: 24px;
        font-size: 14px;
      }
    }
    .sub-value {
      border-radius: 4px;
      border: 1px solid #D9D9D9;
      padding: 5px;
    }
    .icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1px 0;
      button {
        margin-bottom: -4px;
      }
    }
    .value::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to right, #fff 40%, var(--color-navyblue) 40%);
      background-size: 8px 1px;
      background-repeat: repeat-x;
    }
    .value:empty::before {
      background-image: linear-gradient(to right, #fff 40%, var(--color-gold) 40%);
      background-size: 6px 2px;
      background-repeat: repeat-x;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  mgl-map {
    display: block;
    height: 180px;
    width: 100%;
  }
  .mat-expanded {
    .expand-icon {
      transform: rotate(90deg);
    }
  }
}
