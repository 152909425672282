.row {
	display: flex !important;
	flex-wrap: wrap !important;
    flex-direction: row !important;
	&._nowrap {
		flex-wrap: nowrap !important; 
	}
}

.col {
    display: flex !important;
    flex-direction: column !important;
}

.col-5 {
    width: 20%;
    padding: 0 10px 10px;

    @include bp-max(sm) {
        width: 100%;
    }
}

.col-4 {
    width: 25%;
    padding: 0 10px 10px;

    @include bp-max(sm) {
        width: 100%;
    }
}

.col-3 {
    width: 33.33%;
    padding: 0 10px 10px;

    @include bp-max(sm) {
        width: 100%;
    }
}

.col-25 {
    width: 40%;
    padding: 0 10px 10px;

    @include bp-max(sm) {
        width: 100%;
    }
}

.col-2 {
    width: 50%;
    padding: 0 10px 10px;

    @include bp-max(sm) {
        width: 100%;
    }
}

.col-2m {
    width: 50%;
    padding: 0 10px 10px;
}

.col-23 {
    width: 66.66%;
    padding: 0 10px 10px;

    @include bp-max(sm) {
        width: 100%;
    }
}

.col-1 {
    width: 100%;
    padding: 0 10px 10px;
}
