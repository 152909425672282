/* Text align */
.ta-l {
	text-align: left !important;
}
.ta-c {
	text-align: center !important;
}
.ta-r {
	text-align: right !important;
}
/* white space */
.ws-nw {
	white-space: nowrap !important;
}
.ws-pl {
	white-space: pre-line !important;
}
.ws-n {
	white-space: normal !important;
}
.wb-bw {
	word-break: break-word !important;
}
/* font weight */
.fs-10 {
	font-size: 10px !important;
}
.fs-11 {
	font-size: 11px !important;
}
.fs-12 {
	font-size: 12px !important;
}
.fs-14 {
	font-size: 14px !important;
}
.fs-16 {
	font-size: 16px !important;
}
.fs-18 {
	font-size: 18px !important;
}
.fs-20 {
	font-size: 20px !important;
}
.fs-22 {
	font-size: 22px !important;
}
.fs-25 {
	font-size: 25px !important;
}
.fs-28 {
	font-size: 28px !important;
}
/* font weight */
.fw-300 {
	font-weight: 300 !important;
}
.fw-400 {
	font-weight: 400 !important;
}
.fw-500 {
	font-weight: 500 !important;
}
.fw-700 {
	font-weight: 700 !important;
}
.fw-b {
	font-weight: bold !important;
}
/* text-transform */
.tt-u {
	text-transform: uppercase !important;
}
.tt-c {
	text-transform: capitalize !important;
}
/* text-decoration */
.td-u {
	text-decoration: underline !important;
}

.text-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-link {
  cursor: pointer!important;
  text-decoration: underline!important;
}

.pointer {
  cursor: pointer!important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.capitalize-first {
	text-transform: lowercase;
}

.capitalize-first::first-letter {
	text-transform: uppercase;
}
