/* Add needed sized separeted by space: $width: 0 10 20 30; */
@use "sass:math";
$width: 0 28 30 32 54 72 96 100 128 150 172 184 200 300 400;
$width-p: 0 4 5 8 10 11 12 15 20 25 30 33 35 40 45 48 50 55 58 60 75 80 100;
$height: 0 20 21 28 30 32 36 40 50;
$height-p: 0 10 25 30 35 40 50 75 100;
$lineheight: 16 22 32 36;
/* Generators */
@mixin width {
    @each $i in $width {
        .w-#{$i} {
            width: #{$i}px !important;
            min-width: #{$i}px !important;
        }
    }
    @each $i in $width-p {
        .wp-#{$i} {
            width: percentage(math.div($i, 100)) !important;
            max-width: percentage(math.div($i, 100)) !important;
        }
    }
}
@mixin height {
    @each $i in $height {
        .h-#{$i} {
            height: #{$i}px !important;
        }
    }
    @each $i in $height-p {
        .hp-#{$i} {
            height: percentage(math.div($i, 100)) !important;
        }
    }
}
@mixin lineheight {
    @each $i in $lineheight {
        .lh-#{$i} {
            line-height: #{$i}px !important;
        }
    }
}


@include width;
@include height;
@include lineheight;
