/* position */
.pos-rel {
  position: relative !important;
}

.pos-abs {
  position: absolute !important;
}

/* overflow */
$overflow: 'visible' 'hidden' 'scroll' 'auto' 'inherit' 'clip' 'initial' 'overlay' 'revert' 'unset';

.of-hide {
  overflow: hidden !important;
}

.ofx-hide {
  overflow-x: hidden !important;
}

.ofy-hide {
  overflow-y: hidden !important;
}

.op-03 {
  opacity: 0.3;
}

.op-04 {
  opacity: 0.4;
}

.op-05 {
  opacity: 0.5;
}

.op-06 {
  opacity: 0.6;
}

.op-07 {
  opacity: 0.7;
}

.chevron {
  position: sticky;
  bottom: 30px;
  width: 100%;
  height: 0;
  z-index: 2;

  &__btn {
    position: absolute !important;
    bottom: 40px;
    right: 40px;
    padding: 0;
    border: none;
    background-color: #000000ab;
    width: 50px;
    height: 50px;
    z-index: 2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    * {
      color: var(--color-white);
    }
  }
}

@mixin overflow {
  @each $i in $overflow {
    .of-#{$i} {
      overflow: #{$i} !important;
    }

    .ofx-#{$i} {
      overflow-x: #{$i} !important;
    }

    .ofy-#{$i} {
      overflow-y: #{$i} !important;
    }
  }
}

@include overflow;
