:root {
  --color-text: #101528;
  --color-black: #000000;
  --color-orange: #ffa500;
  --color-orangeL: #d2a345;
  --color-yellowL: #eeee90;
  --color-yellowLL: #ddcc60;
  --color-darkgrey: #555555;
  --color-grey: #808080;
  --color-greyL: #BAC0CA;
  --color-greyLL: #dfdfdf;
  --background-color: #f1f1f1;
  --color-redLL: #dd8080;
  --color-green: #008000;
  --color-darkgreen: #006400;
  --color-greenL: #9AC193;
  --color-white: #ffffff;
  --color-whiteD: #dbdbdb;
  --color-red: #FF0000;
  --color-redL: #EA4040;
  --color-navyblue: #081444;
  --color-darkgreyblue: #2A2F45;
  --color-darkblue: #484f67;
  --color-blue: #1890FF;
  --color-blueL: #4F74BC;
  --color-blueLL: #8686e7;
  --color-pinkL: #ff4081;
  --color-gold: #b4a27f;
  --color-goldL: #e8c37b;
}
// ===== FONT FAMILLY =====
$ff-base: "Roboto", sans-serif;
$ff-second: 'Sunflower', sans-serif;
// ===== FONT SIZE =====
$fs: 14px;
// ===== BORDER RADIUS =====
$borderRadius: 4px;






