html,
*,
*:before,
*:after {
	box-sizing: border-box;
}
* {
	outline: 0;
}

html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
}
html {
	font-size: $fs;
}
body {
	font-family: $ff-base;
	color: var(--color-text);
	line-height: 1.4;
	position: relative;
}
