/* blocks */
.d-b {
	display: block !important;
}
.d-ib {
	display: inline-block !important;
}
/* flexbox */
.d-f {
	display: flex !important;
}
.fd-c {
	flex-direction: column !important;
}
.fd-r {
	flex-direction: row !important;
}
.jc-sb {
	justify-content: space-between !important;
}
.jc-sa {
	justify-content: space-around !important;
}
.jc-se {
	justify-content: space-evenly !important;
	align-content: center !important;
}
.jc-fs {
	justify-content: flex-start !important;
}
.jc-c {
	justify-content: center !important;
}
.jc-fe {
	justify-content: flex-end !important;
}
.js-fs {
	justify-self: flex-start !important;
}
.js-c {
	justify-self: center !important;
}
.js-fe {
	justify-self: flex-end !important;
}
.ai-c {
	align-items: center !important;
}
.ai-fe {
	align-items: flex-end !important;
}
.ai-fs {
	align-items: flex-start !important;
}
.ai-sb {
	align-items: space-between !important;
}
.as-c {
	align-self: center !important;
}
.as-fe {
	align-self: flex-end !important;
}
.as-fs {
	align-self: flex-start !important;
}
.as-sb {
	align-self: space-between !important;
}
.ac-c {
	align-content: center;
}
.fg-1 {
	flex-grow: 1 !important;
}

.flex {
    flex: 1 1 0%;
    box-sizing: border-box;
}
.f-1-0-50 {
    display: flex !important;
    flex: 1 0 50% !important;
}
.f-1-1-100 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
}
