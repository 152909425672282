@use "@angular/material" as mat;

@include mat.core();

$md-opesjet31: (
  50: #faf5eb,
  100: #f3e5ce,
  200: #ebd4ad,
  300: #e2c28c,
  400: #dcb573,
  500: #d6a85a,
  600: #d1a052,
  700: #cc9748,
  800: #c68d3f,
  900: #bc7d2e,
  A100: #ffffff,
  A200: #b4a27f,
  A400: #ffcf99,
  A700: #ffc480,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-opesjet32: (
  50: #e2e3e5,
  100: #b7b9bf,
  200: #888a94,
  300: #585b69,
  400: #343848,
  500: #101528,
  600: #0e1224,
  700: #0c0f1e,
  800: #090c18,
  900: #05060f,
  A100: #5353ff,
  A200: #2020ff,
  A400: #0000ec,
  A700: #0000d2,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$firestarter-demo-primary: mat.define-palette($md-opesjet32);
$firestarter-demo-accent: mat.define-palette($md-opesjet31, A200);
$firestarter-demo-warn: mat.define-palette(mat.$red-palette);

$firestarter-demo-theme: mat.define-light-theme((color: (primary: $firestarter-demo-primary,
        accent: $firestarter-demo-accent,
        warn: $firestarter-demo-warn,
      ),
    ));

@include mat.all-component-themes($firestarter-demo-theme);

/* ANGULAR MATERIAL OVERRIDE */

mat-form-field {
  font-size: 14px;
  position: relative;
  margin: 6px 0;

  textarea {
    margin: 5px 0 !important;
  }

  .mdc-text-field {
    border-radius: 4px;
  }

  .mat-mdc-form-field-flex {
    min-height: 38px !important;
  }

  .mat-mdc-form-field-infix {
    width: auto !important;
    min-height: 20px !important;
    padding: 0 !important;
    margin: auto 0 !important;
  }

  .mat-mdc-floating-label {
    top: 50% !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    top: 28px !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    position: absolute !important;
    top: 100% !important;
  }

  .mat-mdc-form-field-icon-suffix {
    display: flex;

    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 36px;
      height: 36px;
    }
  }

  .mat-mdc-form-field-icon-prefix>.mat-icon,
  .mat-mdc-form-field-icon-suffix>.mat-icon {
    padding: 0;
  }

  &.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper,
  &.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  &.mat-form-field-appearance-fill {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0 12px !important;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: 12px !important;
  }

  .mat-mdc-table .mdc-data-table__header-row {
    font-size: 14px !important;
  }

  tr.mat-mdc-header-row {
    font-size: 14px !important;
  }
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100% !important;
}

.mdc-list-item__content {
  overflow: visible !important;
}

.mat-badge-content {
  font-size: 12px;
}

.mat-mdc-menu-item .mdc-list-item__primary-text, .mat-mdc-menu-item-text {
  width: 100%;
  display: flex;
  align-items: center;
}

.mat-mdc-menu-item .mat-icon {
  overflow: visible;
}

.mat-mdc-outlined-button:not(:disabled) {
  border: 1px solid var(--color-text) !important;
  border-radius: 4px;

  &.mat-accent {
    border: 1px solid var(--color-gold) !important;
  }
}

.mat-mdc-raised-button:not(.mdc-button-disabled) {
  &.mat-accent {
    color: var(--color-white);
  }
}

.mdc-icon-button:not(.mat-mdc-paginator-navigation-previous, .mat-mdc-paginator-navigation-next) {
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;

  svg,
  img {
    width: auto !important;
    height: auto !important;
  }
}

.mdc-button__label {
  display: flex;
  align-items: center;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-drawer-inner-container {
  padding: 0 !important;
}












.cdk-global-scrollblock {
  overflow: hidden;
  position: static;
}

.no-padding-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    height: initial;
  }
}

@media screen and (max-width: 768px) {
  .mob-full-dialog {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 992px) {
  .mob-overflow {
    overflow: hidden;
  }
}

.event {
  margin: 21px !important;
  padding: 10px 16px !important;

  &.mat-mdc-snack-bar-container {
    max-width: 100% !important;
  }
}

.scroll-not-safari {
  overscroll-behavior-y: none;
  body {
    overscroll-behavior-y: none;
  }

  ::-webkit-scrollbar {
    max-width: 11px;
  }
}

::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 34px;

  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

._oa {
  &:after {
    content: "OA";
    border: 1px solid black;
    padding: 1px 1px 0 1px;
    margin-left: 5px;
    font-size: 12px;
  }
}

.fill-gold {
  filter: brightness(0) saturate(100%) invert(67%) sepia(21%) saturate(426%) hue-rotate(1deg) brightness(93%) contrast(92%);
}

.fill-grey {
  filter: brightness(0) opacity(30%);
}

app-root, .cdk-describedby-message-container, .cdk-overlay-container {
  @media screen and (max-width: 768px) {
    zoom: 95%;
  }

  @media screen and (max-width: 576px) {
    zoom: 90%;
  }
}

.install-alerted {
  app-shell {
    min-height: calc(100% - 65px);
    @media screen and (max-width: 768px) {
      min-height: calc(100% - 103px);
    }
    .flight-requests {
      min-height: calc(100dvh - 201px);
      @media screen and (max-width: 768px) {
        min-height: auto;
      }
    }
  }
}


.flag {
  @media screen and (max-width: 576px) {
    zoom: 111.111111111111%;
  }
}

* {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}


