.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 64px;
  padding: 0 20px;
  border-bottom: 1px solid rgba(16, 21, 40, 0.3);
  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-right: 5px;
  }
  &__daterangepicker {
    max-width: 220px;
  }


}
.list-container {
  padding: 10px 20px;
  .mat-expansion-panel {
    .expand-icon{
      margin-right: 20px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      transform-origin: center;
      z-index: 2;

      &._active {
        transform: rotate(90deg);
      }
    }
    &.mat-expanded {
      .is-exp-icon{
        .expand-icon{
          transform: rotate(90deg);
        }
      }
    }
    &.no-shadow {
      box-shadow: none !important;
    }

    .mat-expansion-panel-header {
      &.mat-mdc-row {
        height: auto!important;
        min-height: 72px!important;
      }
    }
    .mat-expansion-panel-body {
      padding: 0;
    }
    section {
      display: flex;
    }
    td.mat-mdc-cell {
      border-top: 2px solid rgb(16, 21, 40, 0.2);
      padding-right: 5px;
    }
    tr.mat-mdc-row {
      height: 74px!important;
    }
    .mat-mdc-cell, .mat-mdc-header-cell {
      font-size: 14px;
      font-weight: 500;
      word-wrap: break-word !important;
      white-space: unset !important;
      overflow-wrap: break-word;
      border-bottom-style: none!important;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }


    .icao {
      max-width: 100px;
      background: rgba(16, 21, 40, 0.2);
      border-radius: 26px;
      padding: 6px 10px;
      margin-right: 5px;
      white-space: nowrap;
      .icao-date {
        font-weight: 300;
        font-size: 17px;
        letter-spacing: -0.41px;
        color: var(--color-black);
        opacity: 0.5;
      }
    }
    .flight-date {
      font-family: 'Sunflower', sans-serif;
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.771846px;

      color: var(--color-text);
      display: block;
      &-wd {
        font-weight: 300;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.41px;
      }
    }
    .created-date {
      font-family: 'Sunflower', sans-serif;
      font-weight: 500;
      font-size: 12px;
      opacity: 0.5;
      letter-spacing: -0.2px;
    }
    .fi {
      margin-right: 5px;
      min-width: 22px;
      height: 14px;
      background-size: cover!important;
    }
    .from-to-block {
      font-family: Sunflower, sans-serif;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.24px;
      &__name {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.41px;
        color: var(--color-black);

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 16vw;
      }
      &__country {
        opacity: 0.5;
      }
    }
    .budget-total {
      color: var(--color-black);
      min-width: 120px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }
    .price {
      font-size: 20px;
      &.zero {
        opacity: 0.5;
        font-size: 18px;
      }
    }
    .lh36 {
      line-height: 36px;
    }
    .status-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: var(--color-text);
      &.quiet {
        opacity: 0.6;
      }
    }
    .btn-icon {
      span {
        flex-grow: 1;
        text-align: center;
      }
    }
  }
  table.mat-mdc-table {
    width: 100%;
    min-width: unset !important;
  }
  tr.mat-mdc-header-row {
    height: 42px!important;
  }
  .offers-total{
    text-align: right;
    padding-right: 24px;
    border:none !important;
    background: var(--background-color);
    &__title{
      text-decoration: underline;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color:  var(--color-text);
      opacity: 0.6;
      &.quiet {
        opacity: 0.4;
        text-decoration: none;
        pointer-events: none;
      }
    }
  }
  .offer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 38px;
    margin: 1px;
  }
  .offer-canceled {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(3px);
    z-index: 1;
    top: 0;
    left: 0;
    &__title {
      width: 272px;
      font-weight: 500;
      font-size: 16px;
      color: #101528AA;
      padding: 8px 18px;
      box-shadow: 0px 2px 8px 3px rgba(0, 0, 0, 0.3);
      border: 1px solid #101528AA;
      outline: 3px solid var(--background-color);
      border-spacing: 2px;
      background: var(--background-color);
    }
  }
  .mat-action-row {
    padding: 18px 24px;
  }
  .empty-text{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 32px;
    color: var(--color-grey);
  }
}
.passengers-block {
  max-width: 120px;
  height: 42px;
  border: 1px solid var(--color-text);
  align-content: center;
  text-align: center;
  .passengers-icon {
    width: 100%;
  }
}
.custom-tooltip {
  margin-bottom: -20px !important;
  padding: 5px 20px 5px 20px !important;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  &.__new {
    background-color: var(--color-greenL) !important;
  }
  &.__cancelled {
    background-color: var(--color-yellowL) !important;
    color: var(--color-black);
  }
  &.__lost {
    background-color: var(--color-blueLL) !important;
  }
  &.__expired {
    background-color: var(--color-grey) !important;
  }
  &.__banned {
    background-color: var(--color-redLL) !important;
  }
  &.__landed {
    background-color: var(--color-blueL) !important;
  }
}
