.modal-top{
  border-bottom: solid 1px #DBDBDB;
  padding: 20px 30px;
  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
  }
  &__btn{
  }
}

.modal-container{
  padding: 30px;
  width: 100%;
}

.modal__inner{
  display: flex;
  width: 100%;
  padding-top: 5px;
  &-left{
    display: flex;
    width: 100%;
  }
  &-right{
    display: flex;
    width: 100%;
  }
}

.modal-bottom{
  padding: 14px 30px;
  display: flex;
  justify-content: flex-end;
  border-top: solid 1px #DBDBDB;
  &__btn{
    width: 150px;
    height: 30px;
    cursor: pointer;
  }
}

.bdrop {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
}
