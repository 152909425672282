.profile-wrapper {
  width: 100%;
  .info {
    width: 100%;
    background: var(--color-white);

    padding: 15px 30px;
    &.nopad {
      padding: 0;
    }
    .info-name {
      font-size: 22px;
      line-height: 30px;
      color: var(--color-black);

    }
    .info-status {
      font-style: italic;
      font-size: 22px;
      line-height: 30px;
      color: var(--color-grey);
    }
    .negative-status {
      font-style: italic;
      font-size: 22px;
      line-height: 30px;
      color: var(--color-red);
    }
    ._active {
      color: var(--color-green);
    }
    .info-license {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: var(--color-text);
    }
    .info-docs {
      padding-right: 10px;
      &__name {
        margin-bottom: 10px;
        word-break: break-word;
      }
      &__btn {
        margin-bottom: 10px;
      }
      &__title {
        cursor: pointer; 
        text-decoration: underline;
      }
    }
    &__right {
      min-width: 300px;
      background: var(--color-white);
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      @media screen and (max-width: 450px) {
        min-width: 100%;
      }
      .right-head {
        font-weight: 500;
        font-size: 16px;
        height: 48px;
        padding: 8px 15px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      }
      .right-list {
        position: relative;
        .scroll {
          max-height: calc(313px - 48px);
          width: 100%;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          .empl-item {
            color: var(--color-text);
            padding: 4px 15px;
            &:hover {
              cursor: pointer;
              background: #10152816;
            }
            .my-empl {
              color: rgba(210, 163, 69, 1);
            }
          }
        }
      }
    }
  }

  .middle {
    height: 40%;
    .action {
      margin-top: 15px;
      padding: 28px 30px;
      background-color: var(--color-white);
      &__title {
        font-size: 22px;
        line-height: 26px;
        color: var(--color-black);
      }
      &__btn {
        width: 128px;
        height: 32px;
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 0;
    background: var(--color-white);
    min-height: 180px;
    width: 100%;
    border-top: 1px solid rgba(16,21,40, 0.3);
    &__section {
      padding: 24px 30px;
      min-width: 180px;

    }
    &__title {
      height: 44px;
      font-weight: 500;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: var(--color-text);
    }
    .content-title {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: var(--color-black);
    }
  }
}
