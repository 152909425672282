/* Add needed sized separeted by space: $padding-px: 0 10 20 30; */
$padding-px: 0 5 10 15 20 25 30;
$padding-x-px: 0 5 10 12 15 20 25 30 35 50;
$padding-y-px: 0 5 10 15 20 25 30;
$padding-top-px: 0 5 10 15 20 25 30;
$padding-bottom-px: 0 5 10 15 20 25 30 80 100 120 140 160;
$padding-left-px: 0 2 5 10 15 16 20 25 30 85;
$padding-right-px: 0 2 5 10 15 16 20 25 30;

/* Generators */
@mixin padding {
    @each $i in $padding-px {
        .p-#{$i} {
            padding: #{$i}px !important;
        }
    }
    @each $i in $padding-x-px {
        .px-#{$i} {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }
    }
    @each $i in $padding-y-px {
        .py-#{$i} {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }
    }
    @each $i in $padding-top-px {
        .pt-#{$i} {
            padding-top: #{$i}px !important;
        }
    }
    @each $i in $padding-bottom-px {
        .pb-#{$i} {
            padding-bottom: #{$i}px !important;
        }
    }
    @each $i in $padding-right-px {
        .pr-#{$i} {
            padding-right: #{$i}px !important;
        }
    }
    @each $i in $padding-left-px {
        .pl-#{$i} {
            padding-left: #{$i}px !important;
        }
    }
}

@include padding;
