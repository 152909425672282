html {
	line-height: 1.15; /* 1 */
	-webkit-text-size-adjust: 100%; /* 2 */
}
body {
	margin: 0;
}
main {
	display: block;
}
h1 {
	font-size: 2em;
	margin: 0;
}
hr {
	box-sizing: content-box; /* 1 */
	height: 0; /* 1 */
	overflow: visible; /* 2 */
}
pre {
	font-family: monospace, monospace; /* 1 */
	font-size: 1em; /* 2 */
}
a {
	background-color: transparent;
}
img {
	border-style: none;
}
button,
input,
optgroup,
select,
textarea {
	font-family: inherit; /* 1 */
	font-size: 100%; /* 1 */
	line-height: 1.15; /* 1 */
	margin: 0; /* 2 */
}
button,
input {
	/* 1 */
	overflow: visible;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
}
textarea {
	overflow: auto;
}
[type="checkbox"],
[type="radio"] {
	box-sizing: border-box; /* 1 */
	padding: 0; /* 2 */
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
[type="search"] {
	-webkit-appearance: textfield; /* 1 */
	outline-offset: -2px; /* 2 */
}
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
::-webkit-file-upload-button {
	-webkit-appearance: button; /* 1 */
	font: inherit; /* 2 */
}
[hidden] {
	display: none;
}
img{
	display: inline-block;
	vertical-align: top;
}
a {
	text-decoration: none;
	&:visited{
		color: inherit;
	}
}
